@primaryColor: #6179F9;

.rc-page-loader, .rc-page-loader * {
  box-sizing: border-box;
}

.rc-page-loader {
  height: 15px;
  width: calc(30px * 3);
}

.rc-page-loader .dot {
  width: 15px;
  height: 15px;
  margin: 0 calc(15px / 2);
  border: calc(15px / 5) solid @primaryColor;
  border-radius: 50%;
  float: left;
  transform: scale(0);
  animation: rc-page-loader-animation 1000ms ease infinite 0ms;
}

.rc-page-loader .dot:nth-child(1) {
  animation-delay: calc(300ms * 1);
}

.rc-page-loader .dot:nth-child(2) {
  animation-delay: calc(300ms * 2);
}

.rc-page-loader .dot:nth-child(3) {
  animation-delay: calc(300ms * 3);

}

@keyframes rc-page-loader-animation {
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@link-color: #6179F9;@primary-color: #6179F9;@dark-primary-color: #6166F0;@light-primary-color: #B4C4FA;@ultra-light-primary-color: #E2E7FE;@cashflow-color: #6179F9;@light-cashflow-color: #B4C4FA;@ultra-light-cashflow-color: #E2E7FE;@success-color: #4DAD71;@error-color: #F74141;@splitted-complementary-left-color: #F9CE61;@cashin-color: #4DAD71;@cashout-color: #F74141;@light-cashin-color: #BCDBC5;@ultra-light-cashin-color: #DFF7E4;@light-cashout-color: #F6BBBA;@ultra-light-cashout-color: #FFE8E8;@base-color: #504C4C;