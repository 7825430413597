@import '~antd/dist/antd.less';

@dark-color: fade(@black, 70%);
@border-radius-base: 6px;
@checkbox-radius: 2px;
@grid-gutter-width: 20px;
@text-color: @base-color;
@subtext-color: fade(@black, 30%);
@card-padding-base: 20px;
@menu-item-border: 60px;
@body-background: white;
@layout-body-background: white;
@border-color-base: #EDEDED;
@table-header-bg-sm: white;
@appbar-width: 82px;
@layout-header-height: 52px;

@picker-date-hover-range-color: @ultra-light-primary-color;

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  color: @text-color;
}

// SHADOWS
.rc-light-left-shadow {
  -webkit-box-shadow: -3px 3px 6px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: -3px 3px 6px 0px rgba(0, 0, 0, 0.05);
  box-shadow: -3px 3px 6px 0px rgba(0, 0, 0, 0.05);
}

.rc-basic-shadow {
  -webkit-box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.1);
}

.ant-typography mark {
  background-color: @yellow-3;
}

.ant-form-item-control {
  line-height: 1;
}

.rc-large-text {
  font-size: 20px;
}

// GRID FIX
.ant-row-flex {
  margin-left: -10px;
  margin-right: -10px;
}

// SIDER
.rc-appbar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: @appbar-width;
  background: white;
  border-right: 1px solid @border-color-base;
  z-index: 1;
}

.rc-navbar {
  position: fixed;
  top: 2 * @appbar-width;
}

.rc-logo-container {
  padding-top: 16px;
}

.rc-navbar-link {
  display: flex;
  justify-content: center;
  align-items: center;
  // color: @ultra-light-primary-color;
  padding: 0 !important;
  height: 70px;
  width: @appbar-width;
  margin: 0 !important;
}

.rc-navbar-link .rc-navbar-link-icon-container {
  width: @appbar-width - 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  // padding: 14px 24px;
  border-radius: 6px;
  background-color: transparent;
  color: black;
  @apply border;
  border-color: transparent;
  transition: color .7s, background-color .3s, border-color .7s;
}

// .rc-navbar-link.selected .rc-navbar-link-icon-container, .rc-navbar-link:hover .rc-navbar-link-icon-container {
// @apply border-gray-200;
// @apply bg-gray-100;
// }

.rc-navbar-link.selected .rc-navbar-link-icon-container,
.rc-navbar-link:hover .rc-navbar-link-icon-container {
  // color: @primary-color;
  background-color: @ultra-light-cashflow-color;
}

.rc-navbar-link.selected .rc-navbar-link-icon-container.rc-cashin,
.rc-navbar-link:hover .rc-navbar-link-icon-container.rc-cashin {
  // color: @success-color;
  background-color: @ultra-light-cashin-color;
}

.rc-navbar-link.selected .rc-navbar-link-icon-container.rc-cashout,
.rc-navbar-link:hover .rc-navbar-link-icon-container.rc-cashout {
  background-color: @ultra-light-cashout-color;
  // color: @error-color;
}


.rc-navbar-link.selected .rc-navbar-link-icon,
.rc-navbar-link:hover .rc-navbar-link-icon {
  color: @cashflow-color;
}

.rc-navbar-link.selected .rc-cashin .rc-navbar-link-icon,
.rc-navbar-link:hover .rc-cashin .rc-navbar-link-icon {
  color: @success-color;
}

.rc-navbar-link.selected .rc-cashout .rc-navbar-link-icon,
.rc-navbar-link:hover .rc-cashout .rc-navbar-link-icon {
  color: @error-color;
}

// .rc-navbar-link:hover .rc-navbar-link-icon {
//   // background-color: @primary-color;
//   // @apply text-gray-700;
//   // @apply border-gray-200;
//   // border-color: 1px solid @bg-gray-200;
//   // .rc-basic-shadow();
// }

.rc-sider-element-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.rc-sider-link {
  // position: absolute;
  width: @appbar-width;
  height: 70px;
  // left: 0;
  // bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  // overflow: visible;
}


.rc-sider-wisp-container {
  .rc-sider-link();
  width: @appbar-width - 20px;
  height: 50px;
  margin: 10px;

  // margin-right: 10px;
  // bottom: 84px;
  @media(max-height: 520px) {
    visibility: hidden;
  }
}

.rc-sider-link:hover .rc-bell-icon {
  // width: 14px;
  animation: bellRing 1.2s forwards;
}

@keyframes bellRing {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

.rc-sider-link:hover .rc-settings-icon {
  animation: cogSpin 1.5s forwards;
}

@keyframes cogSpin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(180deg);
  }
}

.rc-sider-link:hover .rc-fire-icon {
  animation: spark 1.2s forwards;
}

@keyframes spark {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(0.9);
    color: red;
  }

  30% {
    transform: scale(1.1);
    color: yellow;
  }

  50% {
    transform: scale(0.8);
    color: orange;
  }

  100% {
    transform: scale(1);
  }
}

// LAYOUT
.rc-layout {
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.rc-screen-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - @appbar-width);

  @media(max-width: 1079px) {
    overflow-x: scroll;
  }
}

.rc-screen-container section {
  @media(max-width: 1079px) {
    min-width: calc(1080px - @appbar-width);
  }
}

// LAYOUT HEADER
.rc-header {
  z-index: 1;
  height: @layout-header-height;
  background: white;
  padding: 0;
  // -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
  // -moz-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
  // box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
}

.rc-header-menu {
  height: @layout-header-height;
  padding: 0 20px;
}

.rc-header-menu .ant-menu-item {
  height: @layout-header-height;
  line-height: @layout-header-height;
}

.rc-header .rc-header-title {
  line-height: @layout-header-height;
  float: left;
  padding: 0 20px;
  font-size: 16px;
}

// SIGN UP
// .rc-signup-background {
//   position: absolute;
//   width: 100vw;
//   height: 100vh;

//   background-size: cover;
//   background-image: url('/rocketchart-dashboard-blurred.jpg');

//   // filter: saturate(80%);
//   opacity: 0.7;

//   @media(max-width: 991px) {
//     background-image: none;
//   }
// }

// SIGN IN
.rc-signin-logo-container {
  // width: @screen-xs;
  // margin-bottom: 10px;
  // margin-top: -40px;
  // align-self: center;
  height: 60px;
}

.rc-signin-logo {
  shape-rendering: geometricPrecision;
  height: 60px;
}

.rc-content {
  position: relative;
  padding: 30px;
  overflow-y: auto;
  // background: white;
  @apply bg-gray-100;

  // Made with https://cssgradient.io/
  // background: @ultra-light-primary-color;
  // background: -moz-linear-gradient(145deg, rgba(218,224,252,1) 0%, rgba(239,241,255,1) 66%, rgba(246,247,255,1) 100%);
  // background: -webkit-linear-gradient(145deg, rgba(218,224,252,1) 0%, rgba(239,241,255,1) 66%, rgba(246,247,255,1) 100%);
  // background: linear-gradient(145deg, rgba(218,224,252,1) 0%, rgba(239,241,255,1) 66%, rgba(246,247,255,1) 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dae0fc",endColorstr="#f6f7ff",GradientType=1);
}

.rc-full-page-box-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

// @media(max-height: 800px) {
//   .rc-full-page-box-container {
//     justify-content: flex-start;
//     margin-top: 60px;
//   }
// }

.rc-bottom-banner {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px;
  pointer-events: none;
}

.rc-box-container {
  background: @white;
  width: @screen-xs;
}

.rc-box-container form {
  padding-left: 10px;
  padding-right: 10px;
}

.rc-margin-top {
  margin-top: @grid-gutter-width;
}

.rc-padding-top {
  padding-top: @grid-gutter-width;
}

.rc-no-margin {
  margin: 0 !important;
}

.rc-no-padding {
  padding: 0 !important;
}

// DASHBOARD
.rc-main-chart .rc-show-on-main-chart-hover {
  visibility: hidden;
}

.rc-main-chart:hover .rc-show-on-main-chart-hover {
  visibility: visible;
}

.rc-dashboard-bottom-spacing {
  min-height: 30px;
}

.rc-dashboard-charts-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 600px;
}

.rc-dashboard-head-charts-container {
  margin-bottom: 20px;
}

.rc-dashboard-head-chart {
  height: 140px;

  @media(min-height: 960px) {
    height: 170px;
  }
}

.rc-full-card-graph {
  height: 70px;
  position: absolute;
  bottom: 0px;
  left: -5px;
  right: -5px;

  @media(min-height: 960px) {
    height: 100px;
  }
}

// RADIO GROUP
.rc-custom-radio.ant-radio-group {
  border: @border-color-base 1px solid;
  border-radius: 4px;
}

.rc-custom-radio .ant-radio-button-wrapper:first-child {
  margin: 3px 0 3px 3px;
  border-radius: 4px;
  border: none;
}

.rc-custom-radio .ant-radio-button-wrapper:last-child {
  margin: 3px 3px 3px 0;
  border-radius: 4px;
  border: none;
}

.rc-custom-radio .ant-radio-button-wrapper:not(:first-child)::before {
  content: none;
}

.rc-custom-radio .ant-radio-button-wrapper-checked {
  border-left-width: 0 !important;
}

// .rc-custom-radio .ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled {
//   background-color: @ultra-light-primary-color;
// }

.rc-custom-radio .ant-radio-button-wrapper-disabled:not(.ant-radio-button-wrapper-checked) {
  background-color: transparent;
}

// TABLE ROWS

.rc-table-secondary-column {
  opacity: 0.75;
}

.rc-cf-st-row:hover .rc-table-secondary-column {
  opacity: 1;
}

.rc-cf-st-row .ant-table-cell-content {
  height: 100%;
}

.rc-cashflow-row>td {
  background-color: lighten(@ultra-light-cashflow-color, 3%);
}

.rc-secondary-row>td {
  background-color: lighten(#f3f3f3, 2%);
}

.rc-cashin-row>td {
  background-color: lighten(@ultra-light-cashin-color, 3%);
}

.rc-cashout-row>td {
  background-color: lighten(@ultra-light-cashout-color, 3%);
}

.rc-hidden-row {
  pointer-events: none;
  opacity: 0;
  transform: scaleX(1.1) scaleY(1.1);
}

.rc-absolute-row {
  position: relative;
  transform: scale(1);
}

.rc-absolute-row .ant-table-cell {
  position: unset;
}


// CARD
// .ant-card {
// -webkit-box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.1);
// -moz-box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.1);
// box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.1);
// background-color: rgba(255, 255, 255, 0.7);
// }

.rc-settings-dropdown {
  cursor: pointer;
}

.rc-settings {
  width: 500px;
  background-color: white;
  overflow: hidden;
  .rc-basic-shadow();
}

.rc-settings-menu.ant-menu {
  border-right: none;
}

.rc-settings-menu.ant-menu-vertical .ant-menu-item {
  margin-top: 0;
  margin-bottom: 0;

}

// Typography

.ant-typography.rc-cashflow {
  color: @cashflow-color;
}

.ant-typography.rc-revenue {
  color: @cashin-color;
}

.ant-typography.rc-expense {
  color: @cashout-color;
}

// TABLES
.rc-min-table .ant-table-placeholder {
  display: none;
}

// .rc-min-table .ant-table-thead {
//   background-color: transparent;
//   border: none !important;
// }

.rc-min-table .ant-table-thead>tr>th,
.rc-min-table .ant-table-tbody>tr>td {
  border: none !important;
  padding: 2px 6px !important;
  // background-color: transparent;
}

.rc-min-table .ant-table-container {
  border: none !important;
}

.ant-table-container {
  border-radius: 6px;
  overflow: hidden;
}

.rc-transaction-table .ant-table-container {
  overflow: visible;
}

// Collapse
.rc-min-collapse .ant-collapse-header {
  padding: 0 !important;
  border: none;
}

.rc-min-collapse .ant-collapse-item {
  border: none;
}

// Checkbox
.ant-checkbox-inner,
.ant-tree-checkbox-inner,
.ant-select-tree-checkbox-inner {
  // default border color
  border-color: hsv(0, 0, 80%);
}

.rc-cashflow-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: @cashflow-color;
  border-color: @cashflow-color;
}

.rc-cashflow-checkbox:hover .ant-checkbox-inner,
.rc-cashflow-checkbox .ant-checkbox-checked::after {
  border-color: @cashflow-color;
}

.rc-revenue-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: @cashin-color;
  border-color: @cashin-color;
}

.rc-revenue-checkbox:hover .ant-checkbox-inner,
.rc-revenue-checkbox .ant-checkbox-checked::after {
  border-color: @cashin-color;
}

.rc-expense-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: @cashout-color;
  border-color: @cashout-color;
}

.rc-expense-checkbox:hover .ant-checkbox-inner,
.rc-expense-checkbox .ant-checkbox-checked::after {
  border-color: @cashout-color;
}

.rc-black-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: @black;
  border-color: @black;
}

.rc-large-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rc-large-checkbox .ant-checkbox {
  top: auto;
}

.rc-large-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-radius: @checkbox-radius;
}

.rc-large-checkbox .ant-checkbox-inner::after {
  left: 30%;
}

// ACCOUNTS
.rc-account-logo-container {
  width: 32px;
  height: 32px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @normal-color;
}

.rc-account-favicon {
  width: 20px;
}

.rc-account-default-icon {
  font-size: 20px;
}

// MENU ITEM
.ant-dropdown-menu-item {
  display: flex;
  align-items: center;
}

// .ant-btn:not(.rc-no-shadow)  { 
//   .rc-basic-shadow();
// }

.ant-btn.ant-btn-link {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

// SELECT
// .ant-select:not(.rc-no-shadow) .ant-select-selector, .ant-picker, .ant-checkbox, .ant-tree-checkbox, .ant-input-number {
//   .rc-basic-shadow();
// }

.ant-select-selection-search input {
  box-shadow: none !important;
}

// .ant-input-search, .rc-round-select .ant-select-selector {
//   border-radius: 20px !important;
// }

.rc-select-without-border .ant-select-selection,
.rc-input-without-border .ant-input {
  border: none;
}

.rc-text-small {
  font-size: @font-size-base - 2;
}

.rc-primary-select .ant-select-selection-placeholder,
.rc-primary-select .ant-select-selection-item {
  color: @primary-color;
  opacity: 1;
  @apply font-semibold;
}

// SCROLLBAR
.rc-show-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
}

.rc-show-scrollbar::-webkit-scrollbar:horizontal {
  height: 6px;
}

.rc-show-scrollbar::-webkit-scrollbar:vertical {
  width: 6px;
}

.rc-show-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.1);
}

// VALIDATE BUTTON
.rc-validation-button {
  z-index: 0;
}

.rc-validation-button:before {
  display: block;
  z-index: -1;
  background-color: @success-color;
  clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%);
  -webkit-animation: validationFill linear .75s;
  animation: validationFill linear .75s;
  opacity: 1;
}

.rc-validation-button-validated {
  background-color: @success-color !important;
}

@keyframes validationFill {
  from {
    clip-path: polygon(0% 100%, 0% 100%, 0% 0%, 0% 0%);
  }

  to {
    clip-path: polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%);
  }
}

// TABLE
.rc-table-primary-column {
  opacity: 1;
  // overflow-y: hidden;
  border-right-width: 3px !important;
  border-left: 2px @border-color-base solid;
}

.rc-table-no-border-left {
  border-left: none;
}

.rc-thin-border-right {
  border-right-width: 1px !important;
}

.rc-table-row-ignored {
  opacity: 0.62;
  filter: grayscale();
}

// MULTIPLE TRANSACTION UPDATE
.rc-multiple-update {
  // OVER CRISP
  z-index: 1000;

  position: fixed;
  bottom: 0;
  left: @appbar-width + 110px;
  right: 110px;
  padding: 10px;
  // padding-bottom: 0;
  background-color: @dark-color;
  border: 1px @border-color-base solid;
  border-bottom: none;
  border-top-left-radius: @border-radius-base;
  border-top-right-radius: @border-radius-base;

  .rc-basic-shadow();

  animation: showBottomBanner 0.5s;
}

@media (max-width: 1600px) {
  .rc-multiple-transaction-update {
    left: @appbar-width;
    right: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-left: none;
    border-right: none;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@keyframes showBottomBanner {
  from {
    opacity: 0;
    bottom: -100px;
  }

  to {
    opacity: 1;
    bottom: 0;
  }
}

.rc-show-on-table-row-hover {
  // display: none;  
  visibility: hidden;
}

.ant-table-row:hover .rc-show-on-table-row-hover {
  // display: initial;  
  visibility: visible;
}

// CATEGORIES
.rc-category-line {
  position: relative;
  padding: 12px;
  border: 1px solid @border-color-base;
  margin-bottom: -1px;
  border-left: none;
  display: flex;
  align-items: center;
  background-color: white;
}

.rc-new-category-line {
  padding: 20px;
}

.rc-category-select-tag-container {
  margin-top: -1px;
}

.rc-category-select-tag-container .ant-tag {
  cursor: pointer;
}

.rc-multi-category-select .ant-select-selector {
  padding-left: 8px;
  padding-right: 8px;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.rc-multi-category-select .ant-select-selector::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0 !important;
  height: 0 !important;
}

.rc-multi-category-select .ant-select-clear {
  border-radius: 50%;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 8px;
  right: 1px;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 1;
}

.rc-multi-category-select .ant-select-selection-item {
  height: auto;
  border: none;
  padding: 0;
  margin: 2px 0;
  background-color: transparent;
}

.rc-multi-category-select .ant-select-selection-item-content {
  height: auto;
  border: none;
  padding: 0;
  margin: 0;
  margin-right: 0;
}

.rc-multi-category-select .ant-select-selection-item-remove {
  position: relative;
  right: 14px;
}

.rc-multi-category-select .ant-tag {
  margin-right: 0;
  padding-right: 16px;
}

// SCENARIO SHEET
.rc-popup-forecast .ant-popover-inner,
.rc-forecast-assistant .ant-popover-inner {
  border-color: @light-primary-color;
  border-width: 2px;
  border-radius: 10px;
}

.rc-popup-forecast .ant-popover-arrow,
.rc-forecast-assistant .ant-popover-arrow {
  border-color: @light-primary-color !important;
}

.rc-popup-forecast .ant-popover-arrow-content::before,
.rc-forecast-assistant .ant-popover-arrow-content::before {
  // background-color: @light-primary-color  !important;
  background: @light-primary-color !important;
}

// .rc-forecast-assistant .ant-popover-arrow {
//   border-color: @border-color-base !important;
// }

// .rc-popover-no-padding .ant-popover-inner-content {
//   padding: 0;
// }

// .rc-editable-cell {
//   padding: 5px 0;
//   cursor: pointer;
// }

// .rc-editable-cell:hover {
//   border: 2px solid @primary-color;
//   border-radius: 4px;
//   height: 30px;
//   padding: 2px 4px;
//   background: white;
// }

// .rc-forecast-comment {
//   visibility: hidden;
// }

// .rc-forecast-cell:hover .rc-forecast-comment {
//   visibility: visible;
// }

// COLOR SELECT
.rc-color-select .ant-select-selector {
  background: transparent;
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
}

// .rc-color-select .ant-select-focused .ant-select-selector {
//   box-shadow: none !important;
// }

.rc-color-select .ant-select-selection-search,
.rc-color-select .ant-select-selection-item {
  display: flex !important;
  align-items: center;
}

.rc-color-select .ant-select-selection-item,
.rc-color-select .ant-select-selection-placeholder {
  overflow: visible;
}

.rc-color-select .ant-select-selection-placeholder {
  opacity: 1;
}

.rc-color-select-dropdown {
  width: 60px !important;
  overflow-x: visible;
}

.rc-color-select-dropdown .ant-select-item-option-content {
  text-overflow: unset;
  overflow: visible;
}

// .rc-color-option {
//   .rc-basic-shadow();
// }

.ant-select-focused .rc-color-option {
  border-width: 1px !important;
}

.rc-color-option {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  @apply border-gray-200;
}

.has-error .rc-color-option {
  border-width: 1px;
  border-color: @error-color;
}

// TEXT
.rc-bold-font {
  font-weight: bold;
}

.rc-optional-label {
  height: 14px;
  margin-top: -14px;
  font-size: 10px;
  // position: absolute;
  width: 100%;
  // text-align: right;
  color: @subtext-color;
}

.rc-success-label {
  color: @success-color;
  .rc-bold-font();
}

.rc-error-label {
  color: @error-color;
  .rc-bold-font();
}

.rc-success-label.active,
.rc-error-label.active {
  opacity: 1;
  animation: labelFadeIn .5s forwards;
}

.rc-success-label.hidden,
.rc-error-label.hidden {
  opacity: 0;
}

@keyframes labelFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes labelFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

// ANTD FIX
.ant-legacy-form-item-control {
  line-height: unset !important;
}

.ant-input-affix-wrapper,
.ant-legacy-form-item input {
  border-radius: @border-radius-base;
}

.ant-input-affix-wrapper input {
  box-shadow: none !important;
}

.ant-input-number {
  border-color: @border-color-base;
}

.ant-switch {
  @apply bg-gray-200 !important;

  &-checked {
    background-color: @primary-color !important;
  }
}

// CHECKBOX OVERRIDE
.ant-checkbox-inner,
.ant-tree-checkbox-inner,
.ant-select-tree-checkbox-inner,
.ant-checkbox-checked::after {
  border-radius: @checkbox-radius;
}

.rc-checkbox-reset-top .ant-checkbox {
  top: 0;
}

/* Safari-Opera specific declarations here */
_::-webkit-full-page-media,
_:future,
:root .rc-category-border {
  display: none;
}

// Onboarding
.rc-onboarding-screen-container {
  width: 768px;
}

.rc-highlight-text,
.rc-highlight-text-cashin,
.rc-highlight-text-cashout {
  position: relative;
  z-index: 0;
}


.rc-highlight-text:after,
.rc-highlight-text-cashin:after,
.rc-highlight-text-cashout:after {
  content: " ";
  height: 10px;
  position: absolute;
  left: 0;
  right: 0;
  margin: -0.1rem;
  // margin-left: 0.4rem;
  top: 70%;
  // width: 102%;
  z-index: -1;
  opacity: 0.8;
}

.rc-highlight-text:after {
  background-color: var(--highlight-color, @ultra-light-primary-color);
}

.rc-highlight-text-cashout:after {
  background-color: @light-cashout-color;
}

.rc-highlight-text-cashin:after {
  background-color: @light-cashin-color;
}

// // Kompassify
// .kompassious-widget {
//   right: 30px !important;
//   bottom: 100px !important;
// }

// .kompassious-checklist-player-container {
//   right: 30px !important;
//   bottom: 160px !important;
// }

// Free Trial
.rc-free-trial-banner {
  position: absolute;
  top: 6px;
  height: 20px;
  left: 30px;
  right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.rc-subscription-card {
  // width: 320px;
  padding: @card-padding-base * 2;
  border-radius: @border-radius-base;
  border: @primary-color 1px solid;

  // Primary shadows
  -webkit-box-shadow: 3px 3px 6px 0px fade(@primary-color, 25%);
  -moz-box-shadow: 3px 3px 6px 0px fade(@primary-color, 25%);
  box-shadow: 3px 3px 6px 0px fade(@primary-color, 25%);
}

.rc-subscription-card button:hover {
  animation: ripples 3s;
}

@keyframes ripples {
  0% {
    box-shadow: 0 0 0px 0px #6179F950;
  }

  25% {
    box-shadow: 0px 0 0 10px #6179F900;
  }

  100% {
    .rc-basic-shadow();
  }
}

// Antd Modal Button Fix
.ant-modal-footer .ant-btn-loading-icon {
  display: inline;
}

// Invoice Table
.rc-recurring-payment-table table,
.rc-accounting-document-table table {
  border-collapse: collapse;
}

.rc-accounting-document-table-expanded-row>.ant-table-cell,
.rc-recurring-payment-table-expanded-row>.ant-table-cell,
.rc-invoice-expected-transaction-table-expanded-row,
.rc-accounting-document-table-row.expanded,
.rc-accounting-document-table-row.expanded>.ant-table-cell:first-child,
.rc-recurring-payment-table-row.expanded,
.rc-recurring-payment-table-row.expanded>.ant-table-cell:first-child {
  box-shadow: 2px 0px 0px 0px @primary-color inset;
}

.rc-recurring-payment-table-row.expanded>.ant-table-cell,
.rc-accounting-document-table-row.expanded>.ant-table-cell {
  border-bottom: none;
}

.rc-invoice-expected-transaction-table-expanded-row>.ant-table-cell {
  box-shadow: 2px 0px 0px 0px @primary-color inset;
}

.rc-recurring-payment-table-expanded-row>.ant-table-cell,
.rc-accounting-document-table-expanded-row>.ant-table-cell {
  box-shadow: 2px 0px 0px 0px @primary-color inset, 0px -10px 10px -10px rgba(0, 0, 0, .1) inset;
}

.rc-recurring-payment-table-row.expanded,
.rc-recurring-payment-table-expanded-row>.ant-table-cell,
.rc-accounting-document-table-row.expanded,
.rc-accounting-document-table-expanded-row>.ant-table-cell {
  background-color: @ultra-light-primary-color;
}


.rc-invoice-expected-transaction-table-expanded-row>.ant-table-cell {
  @apply bg-gray-100;
}

.ant-table-tbody>tr.rc-recurring-payment-table-row:hover>td,
.rc-recurring-payment-table-expanded-row>.ant-table-cell:hover,
.ant-table-tbody>tr.rc-accounting-document-table-row:hover>td,
.rc-accounting-document-table-expanded-row>.ant-table-cell:hover {
  background-color: @ultra-light-primary-color;
}

// CURRENCY INPUT
// .rc-currency-input input {
//   text-align: right;
//   padding-right: 28px;
// }

.rc-currency-input-spinner {
  position: absolute;
  right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  z-index: 2;
}

// .rc-expected-transaction-detail-category-select {
//   max-width: 240px;
// }

.rc-transaction-select-list-container {
  overflow-y: scroll;
}

.rc-search-expected-transactions-list-container {
  overflow-y: scroll;
}

.rc-transaction-expected-transactions-list-container {
  min-height: 90px;
  overflow-y: scroll;
}

// Reconciliation drawer
// .rc-reconciliation-drawer .ant-drawer-content {
//   background:rgba(255, 255, 255, 0.2);
//   backdrop-filter: blur(6px);
// }


// DRAWER
// .ant-drawer-header {
//   // border-bottom: none;
// }

.ant-drawer-title {
  min-height: 34px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

// .ant-drawer-close {
//   position: absolute;
// }

// .ant-drawer-body {
//   padding-top: 16px;
// }

// .rc-unit-input .ant-input-number-handler-wrap {
//   z-index: 1;
// }

.rc-unit-input input {
  text-align: right;
  padding-right: 18px;
}

// POPOVER
.rc-popover-no-padding .ant-popover-inner-content {
  padding: 0px;
  // padding: 4px;
  // opacity: 90%;
}

.rc-dashed-example-dot {
  background: repeating-linear-gradient(45deg,
      @light-primary-color,
      @light-primary-color 3px,
      @ultra-light-primary-color 3px,
      @ultra-light-primary-color 6px);
}

.rc-dashed-example-dot-primary {
  background: repeating-linear-gradient(45deg,
      @primary-color,
      @primary-color 2px,
      @light-primary-color 1px,
      @light-primary-color 6px);
}

.rc-reconciliation-drawer-collapse {
  .ant-collapse-content-active {
    flex-grow: 1;
    overflow: hidden;
  }
}

.rc-reconciliation-drawer-collapse {
  .ant-collapse-content-box {
    height: 100%;
  }
}

.rc-invoice-state-filter {
  .ant-select-selector {
    background-color: transparent !important;
    border-radius: 6px !important;
  }
}

.rc-select-no-border {
  .ant-select-selector {
    border-color: transparent !important;
  }
}

.pdf-viewer-modal {
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .ant-modal-body {
    flex: 1;
    width: 100%;
    padding: 10px;
  }
}
@link-color: #6179F9;@primary-color: #6179F9;@dark-primary-color: #6166F0;@light-primary-color: #B4C4FA;@ultra-light-primary-color: #E2E7FE;@cashflow-color: #6179F9;@light-cashflow-color: #B4C4FA;@ultra-light-cashflow-color: #E2E7FE;@success-color: #4DAD71;@error-color: #F74141;@splitted-complementary-left-color: #F9CE61;@cashin-color: #4DAD71;@cashout-color: #F74141;@light-cashin-color: #BCDBC5;@ultra-light-cashin-color: #DFF7E4;@light-cashout-color: #F6BBBA;@ultra-light-cashout-color: #FFE8E8;@base-color: #504C4C;